import React from "react";
import bgImage from "../assets/OurProducts/bgImage.jpg";
import App from "../assets/OurProducts/App.png";
import Tile2 from "../assets/OurProducts/Tile2.png";
import ConnectTile from "../assets/OurProducts/ConnectTile.png";
import kit from "../assets/OurProducts/kit.png";
import clinic from "../assets/OurProducts/clinic.png";
import Icon1 from "../assets/OurProducts/Icon1.png";
import Icon2 from "../assets/OurProducts/Icon2.png";
import Icon3 from "../assets/OurProducts/Icon3.png";
import bgPortal from "../assets/OurProducts/bgPortal.png";
import Portal from "../assets/OurProducts/Portal.png";
import { MdOutlineArrowOutward } from "react-icons/md";
import Hospital from "../assets/OurProducts/Hospital.png";
import PRN_BG from "../assets/OurProducts/PRN_BG.png";
import Labs from "../assets/OurProducts/Labs.png";
import Health from "../assets/OurProducts/Health.png";
import AppStore from "../assets/OurProducts/AppStore.png";
import PlayStore from "../assets/OurProducts/PlayStore.png";
import Footer from "../components/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
const OurProducts = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white min-h-screen scroll-smooth">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best HIPAA-Compliant & Custom EHR Solutions - AllesHealth
        </title>

        <meta
          name="description"
          content="Get GDPR-compliant, AI-powered healthcare solutions, including remote patient monitoring, voice-recognition EHR & cloud-based practice management software."
        />
        <meta
          name="keywords"
          content="Patient data security solutions for healthcare, AI-powered remote patient monitoring system, Cloud-based practice management software, EHR software with AI voice recognition, GDPR-compliant digital health solutions, AI-driven healthcare solutions in the US, AI-driven healthcare solutions in the UK, AI-driven healthcare solutions in the IN"
        />
      </Helmet>

      {/* BG Image */}
      <div className="homeBg w-[90vw] my-[5vh] mx-[5vw] h-auto">
        <LazyLoadImage
          src={bgImage}
          effect="blur"
          width={"90vw"}
          placeholderSrc={bgImage}
          alt="Background Image"
          className="w-full h-auto object-cover"
        />
      </div>

      {/* TILE1 */}
      <div
        style={{
          background:
            "linear-gradient(90deg, rgba(42, 184, 229, 0.54) 40%, rgba(98, 181, 207, 0.43) 70%)",
        }}
        className="w-[90vw] mx-[5vw] rounded-xl shadow-xl flex flex-col lg:flex-row lg:justify-around items-start lg:items-center py-2 lg:py-0 px-5"
      >
        {/* <img src={Tile1} alt="Tile" className="w-full h-auto object-cover" /> */}
        <div className="flex justify-center items-center p-2">
          <h1 className="bg-[#FACFD9] rounded-l-lg text-lg font-regular text-[#000000] my-1 py-2 text-center px-5">
            Powered By :
          </h1>
        </div>
        <div className="flex flex-row justify-center gap-4 items-center p-2">
          <img src={Icon1} alt="Icon1" className="w-10 h-auto object-cover" />
          <h1 className="text-lg font-regular text-[#000000] my-1 text-center">
            Blockchain
          </h1>
        </div>
        <div className="flex flex-row justify-center gap-4 items-center p-2">
          <img src={Icon2} alt="Icon2" className="w-10 h-auto object-cover" />
          <h1 className="text-lg font-regular text-[#000000] my-1 text-center">
            Big Data Analytics
          </h1>
        </div>
        <div className="flex flex-row justify-center gap-4 items-center p-2">
          <img src={Icon3} alt="Icon3" className="w-10 h-auto object-cover" />
          <h1 className="text-lg font-regular text-[#000000] my-1 text-center">
            Artificial Inteligence
          </h1>
        </div>
      </div>

      {/* Alleshealth Connect */}
      <h1 className="text-4xl lg:text-6xl font-semibold text-[#42B8F7] text-center mt-[8vh] mb-10">
        AllesHealth Connect
      </h1>
      <div className="w-[80vw] mx-[10vw] px-5 flex flex-col justify-center items-center">
        <div className="w-[80vw] mx-[10vw] flex flex-row justify-center items-center gap-5">
          <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
            <div
              style={{ backgroundColor: "rgba(60, 165, 220, 0.09)" }}
              className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]"
            >
              <img
                src={kit}
                alt="Icon"
                className="w-[12vw] md:w-[5vw] h-auto object-contain"
              />
            </div>
            <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">
              For Clinics
            </h1>
          </div>
          <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
            <div
              style={{ backgroundColor: "rgba(60, 165, 220, 0.09)" }}
              className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]"
            >
              <img
                src={clinic}
                alt="Icon"
                className="w-[12vw] md:w-[5vw] h-auto object-contain"
              />
            </div>
            <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">
              For Hospitals
            </h1>
          </div>
        </div>
      </div>
      <div className="w-[90vw] h-auto">
        <img
          src={ConnectTile}
          alt="Tile"
          className="w-[100vw] h-auto object-cover"
        />
      </div>

      {/* New Framer Motion button section with adjusted positioning */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="flex flex-col items-center space-y-4 -mt-10" // Added -mt-10 to move up
      >
        <div className="flex items-center space-x-4">
          <button
            onClick={() =>
              (window.location.href =
                "https://stagedoctor.alleshealth.com/signup")
            }
            className="bg-sky-500 text-white px-8 py-4 rounded-lg font-semibold
      flex items-center gap-2 shadow-xl hover:bg-sky-600
      transition-all duration-300 transform hover:scale-105"
          >
            <span>Get Started FREE</span> <MdOutlineArrowOutward />
          </button>
          <button
            onClick={() => navigate("/BookDemo")}
            className="border border-blue-900 text-blue-900 px-8 py-4 rounded-lg font-semibold
      flex items-center gap-2 hover:bg-blue-50
      transition-all duration-300 transform hover:scale-105"
          >
            Book a Demo <MdOutlineArrowOutward />
          </button>
        </div>
        <p className="text-gray-600 text-sm mt-2">
          Get started with free tools, or get a demo of our premium services.
        </p>
      </motion.div>

      {/* AllesHealth Patients App Section */}
      <h1 className="text-4xl lg:text-6xl font-semibold text-[#42B8F7] text-center mt-[8vh] mb-10">
        AllesHealth Patients App
      </h1>
      <div
        style={{ backgroundColor: "rgba(217, 217, 217, 0.13)" }}
        className="w-[80vw] mx-[10vw] py-[8vh] px-5 flex flex-col justify-center items-center shadow-xl rounded-xl"
      >
        {/* Previous content */}
        <div className="flex justify-center items-center">
          <img src={App} alt="App" className="w-[90vw] h-auto object-contain" />
        </div>

        {/* App Store and Play Store Buttons */}
        <div className="md:w-1/2 mt-10 flex flex-row justify-center items-center gap-10">
          <a
            href="https://apps.apple.com/app/alleshealth/id6449357604"
            target="_blank"
            className="flex justify-center items-center"
          >
            <img
              src={AppStore}
              alt="AppStore"
              className="w-[90vw] h-auto object-contain"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.alleshealth"
            target="_blank"
            className="flex justify-center items-center"
          >
            <img
              src={PlayStore}
              alt="PlayStore"
              className="w-[90vw] h-auto object-contain"
            />
          </a>
        </div>

        {/* New Framer Motion button section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="flex flex-col items-center space-y-4 mt-10"
        >
          <div className="flex items-center space-x-4">
            {/* <button
              onClick={() => window.location.href = 'https://stagedoctor.alleshealth.com/signup'}
              className="bg-sky-500 text-white px-8 py-4 rounded-lg font-semibold
              flex items-center gap-2 shadow-xl hover:bg-sky-600
              transition-all duration-300 transform hover:scale-105"
            >
              <span>Get Started FREE</span> <MdOutlineArrowOutward />
            </button> */}
            <button
              onClick={() => navigate("/BookDemo")}
              className="bg-sky-500 text-white px-8 py-4 rounded-lg font-semibold
              flex items-center gap-2 shadow-xl hover:bg-sky-600
              transition-all duration-300 transform hover:scale-105"
            >
              Book a Demo <MdOutlineArrowOutward />
            </button>
          </div>
          <p className="text-gray-600 text-sm mt-2">
            Get started with free tools, or get a demo of our premium services.
          </p>
        </motion.div>
      </div>

      {/* Partner Portal */}
      <div className="mt-[10vh] w-[100vw] min-h-screen flex flex-col justify-center items-center relative">
        {/* Background Image */}
        <div className="w-full flex justify-center items-center">
          <img
            src={bgPortal}
            alt="background"
            className="w-full h-[120vh] lg:h-[90vw] object-cover"
          />
        </div>

        <div className="absolute top-15 pb-8">
          {/* Header */}
          <h1 className="text-4xl lg:text-6xl font-semibold text-[#42B8F7] text-center mb-5">
            Partner Portal
          </h1>

          <div className="w-[80vw] mx-[10vw] mt-[10vh] mb-10 flex flex-row justify-center items-center gap-5">
            <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
              <div
                style={{ backgroundColor: "rgba(60, 165, 220, 0.09)" }}
                className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]"
              >
                <img
                  src={Health}
                  alt="Icon"
                  className="w-[12vw] md:w-[5vw] h-auto object-contain"
                />
              </div>
              <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">
                For Health Insurers
              </h1>
            </div>
            <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
              <div
                style={{ backgroundColor: "rgba(60, 165, 220, 0.09)" }}
                className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]"
              >
                <img
                  src={Hospital}
                  alt="Icon"
                  className="w-[12vw] md:w-[5vw] h-auto object-contain"
                />
              </div>
              <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">
                For Hospitals/ Clinics
              </h1>
            </div>
            <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
              <div
                style={{ backgroundColor: "rgba(60, 165, 220, 0.09)" }}
                className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]"
              >
                <img
                  src={Labs}
                  alt="Icon"
                  className="w-[12vw] md:w-[5vw] h-auto object-contain"
                />
              </div>
              <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">
                For Laboratories
              </h1>
            </div>
          </div>

          {/* Portal Image */}
          <div className="w-[100vw] py-[8vh] px-5 flex justify-center items-center">
            <img
              src={Portal}
              alt="Portal"
              className="w-[90vw] h-auto object-contain"
            />
          </div>

          {/* Icons */}
          <div className="mt-10 flex flex-col lg:flex-row justify-center items-center gap-10">
            <a
              href="https://partner.alleshealth.com/"
              target="_blank"
              className="bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] items-center w-[60vw] lg:w-[20vw] py-5 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500"
            >
              Become a Partner <MdOutlineArrowOutward />
            </a>
            <div
              onClick={() => {
                navigate("/contactus");
              }}
              className="bg-[#EEC300] flex justify-center font-bold text-[#000000] items-center w-[60vw] lg:w-[20vw] py-5 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500"
            >
              Learn More <MdOutlineArrowOutward />
            </div>
          </div>
        </div>
      </div>

      {/* Patient Referral Network Section */}
      <div className="w-[90vw] h-[25vw] mx-[5vw] my-[5vh] relative bg-[#E7F6F2] rounded-2xl shadow-lg overflow-hidden">
        <div className="flex h-full">
          {/* Left Content */}
          <div className="w-1/2 pl-[6vw] flex flex-col justify-center">
            <div className="flex flex-col items-start">
              <h1 className="text-[2.8vw] font-bold text-[#2C3333] mb-3">
                Patient Referral Net(PRN)
              </h1>
              <p className="text-[1.2vw] font-medium text-[#2C3333] mb-8">
                Most Secure Way to Split Patient Referral Files.
              </p>

              {/* Buttons Stack */}
              <div className="flex flex-col gap-4 ml-8">
                <a
                  href="https://dev.prn.alleshealth.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#3CA5DC] text-white text-[1.1vw] font-medium w-[16vw] py-4 rounded-full flex items-center justify-center gap-2 hover:scale-105 transition duration-300 shadow-[0_8px_16px_rgba(60,165,220,0.3)]"
                >
                  Try Now <MdOutlineArrowOutward />
                </a>
                <a
                  href="https://dev.prn.alleshealth.com/#contactus"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white text-[#2C3333] text-[1.1vw] font-medium w-[16vw] py-4 rounded-full flex items-center justify-center gap-2 hover:scale-105 transition duration-300 cursor-pointer shadow-[0_8px_16px_rgba(0,0,0,0.1)]"
                >
                  Learn More <MdOutlineArrowOutward />
                </a>
              </div>
            </div>
          </div>

          {/* Right Content - Image */}
          <div className="w-1/2 relative flex justify-center items-center px-4 pr-16">
            <img
              src={PRN_BG}
              alt="PRN Illustration"
              className="max-h-[90%] w-auto object-contain border-[4px] border-[#2C3333] rounded-lg ml-12"
            />
          </div>
        </div>
      </div>

      {/* Tile 2 */}
      <div
        onClick={() => {
          navigate("/sunita");
        }}
        className="mt-10 mb-[10vh] w-[86vw] lg:w-[90vw] h-full mx-[5vw] cursor-pointer"
      >
        <img src={Tile2} alt="Tile" className="w-full h-auto object-cover" />
      </div>

      <hr className="mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black" />

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default OurProducts;
