import "./App.css";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import MainHeader from "./screens/MainHeader";
import Home from "./screens/Home";
import AboutUs from "./screens/AboutUs";
import Error from "./screens/Error";
import ForPatientsCaregivers from "./screens/ForPatientsCaregivers";
import Careers from "./screens/Careers";
import ContactUs from "./screens/ContactUs";
import EULA from "./screens/Policy/EULA";
import PolicySecurity from "./screens/Policy/PrivacySecurity";
import TermsCondition from "./screens/Policy/TermsConditions";
import Refunds from "./screens/Policy/Refunds";
import Shipping from "./screens/Policy/Shipping";
import DataPolicy from "./screens/Policy/DataPolicy";
import LegalDisclaimer from "./screens/Policy/LegalDisclaimer";
import OurProducts from "./screens/OurProducts";
import Sunita from "./screens/Sunita";
import ForHospitalsClinics from "./screens/ForHospitalsClinics";
import Aeonix from "./screens/Aeonix";
import Blogs from "./screens/Blogs";
import BlogDetails from "./screens/BlogDetails";
import BookDemo from "./screens/BookDemo";
import ReactGA from "react-ga4";
import { useEffect } from "react";

ReactGA.initialize("G-ELH7J5224C");
ReactGA.send("pageview");


function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainHeader />}>
          <Route index element={<Home />}></Route>
          <Route
            path="ForPatientsCaregivers"
            element={<ForPatientsCaregivers />}
          ></Route>
          <Route
            path="ForHospitalsClinics"
            element={<ForHospitalsClinics />}
          ></Route>
          <Route path="ourProducts" element={<OurProducts />}></Route>
          <Route path="aeonix" element={<Aeonix />}></Route>

          <Route path="aboutus" element={<AboutUs />}></Route>
          <Route path="careers" element={<Careers />}></Route>
          <Route path="contactus" element={<ContactUs />}></Route>
          <Route path="blogs" element={<Blogs />}></Route>
          <Route path="blogs/:slug" element={<BlogDetails />}></Route>
          <Route path="Sunita" element={<Sunita />}></Route>

          <Route
            path="end-user-license-agreement-eula"
            element={<EULA />}
          ></Route>
          <Route
            path="privacy-and-security-policy"
            element={<PolicySecurity />}
          ></Route>
          <Route path="terms-conditions" element={<TermsCondition />}></Route>
          <Route
            path="refund-cancellation-policy"
            element={<Refunds />}
          ></Route>
          <Route path="shipping-policy" element={<Shipping />}></Route>
          <Route path="data-privacy-policy" element={<DataPolicy />}></Route>
          <Route path="legal-disclaimer" element={<LegalDisclaimer />}></Route>
          <Route path="BookDemo" element={<BookDemo />}></Route>
        </Route>
        <Route path="*" element={<Error />} status={404} />
      </Routes>
    </div>
  );
}

export default App;
