import { ChevronRight } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

function BlogCard({ post }) {
  const { featured_image, title, date, excerpt, slug } = post;
  return (
    <div className="bg-white border rounded-2xl shadow-lg overflow-hidden select-none hover:scale-105 transition-all duration-200">
      <img src={featured_image} alt="" className="aspect-video" />
      <div className="p-5">
        <Link to={`/blogs/${slug}`}>
          <div className="text-gray-800 text-sm font-semibold hover:underline">{title}</div>
        </Link>
        <div className="text-neutral-500 text-xs font-semibold mt-1">
          {new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </div>
        <div
          className="text-gray-800 text-xs font-light mt-4"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />

        <div className="h-px w-1/3 bg-neutral-400 my-2" />
        <div className="flex">
          <Link
            to={`/blogs/${slug}`}
            className="flex items-center space-x-0.5 group active:scale-95 transition-all duration-200"
          >
            <div className="text-gray-800 text-xs font-semibold group-hover:underline">
              Read More
            </div>
            <div className="p-1 bg-gray-800 rounded-full group-hover:translate-x-1 transition-all duration-200">
              <ChevronRight strokeWidth={2.75} className="w-2 h-2 text-white" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
