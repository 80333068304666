import React from "react";
import ReactPlayer from "react-player/lazy";
import HomeBg from "../assets/Home/HomeBg.jpg";
import PlayIcon from "../assets/Home/googleplayIcon.png";
import StoreIcon from "../assets/Home/appstoreIcon.png";
import TileNew from "../assets/Home/TileNew.png";
import f1 from "../assets/Home/f1.png";
import f2 from "../assets/Home/f2.png";
import f3 from "../assets/Home/f3.png";
import f4 from "../assets/Home/f4.png";
import f5 from "../assets/Home/f5.png";
import s1 from "../assets/Home/s1.png";
import s2 from "../assets/Home/s2.png";
import s3 from "../assets/Home/s3.png";
import s4 from "../assets/Home/s4.png";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowOutward } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import DoctorImage from "../assets/Home/doctor-landing.jpg";
import DigitalSecurityImage from "../assets/Home/digital-security1.png";

const Home = () => {
  const navigate = useNavigate();
  const handleVideoPlayerError = (error) => {
    console.error("Error playing media:", error);
  };

  return (
    <div className="bg-white min-h-screen scroll-smooth">
      <Helmet>
        <meta charSet="utf-8" />
        <title>AI-Powered & Affordable EHR Solutions - AllesHealth</title>
        <meta
          name="description"
          content="Looking for AI-powered, secure, and interoperable EHR systems with telehealth integration? AllesHealth offers free & affordable EHR solutions for seamless healthcare management."
        />
        <meta
          name="keywords"
          content="Practice fusion EHR, Electronic health record systems, Epic EMR system, Cloud-based EHR, AI-powered EHR, Free EHR solutions, Interoperable EHR systems, Telehealth EHR integration, Secure EHR software, Affordable EHR systems"
        />
      </Helmet>

      <div className="relative min-h-[calc(100vh-4rem)] flex flex-col justify-center items-center">
        {/* Background decoration */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
          <div className="absolute top-0 -left-4 w-72 h-72 bg-blue-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob" />
          <div className="absolute top-0 -right-4 w-72 h-72 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000" />
          <div className="absolute -bottom-8 left-20 w-72 h-72 bg-pink-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000" />
        </div>

        {/* Hero Section */}
        <div className="relative w-[85vw] flex flex-col lg:flex-row justify-between items-center gap-16">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full lg:w-3/5"
          >
            <h1 className="text-5xl lg:text-6xl font-bold text-sky-500 mb-8 leading-tight text-left">
              Free EHR and Patient
              <span className="block text-blue-900">Management Solution</span>
            </h1>
            <p className="text-xl mb-10 text-gray-600 leading-relaxed text-left">
              that grows your Clinical Practice & improves Patient Engagement
              through comprehensive Electronic Health Record tools.
            </p>

            <div
              // initial={{ opacity: 0, y: -50 }}
              // animate={{ opacity: 1, y: 0 }}
              // transition={{ duration: 0.6 }}
              className="flex flex-col space-y-4 mt-6"
            >
              <div className="flex md:items-center md:space-x-4 space-y-4 md:space-y-0 flex-col md:flex-row">
                <button
                  onClick={() =>
                    (window.location.href =
                      "https://ehr.alleshealth.com/signup")
                  }
                  className="bg-sky-500 text-white px-8 py-4 rounded-lg font-semibold
                                    flex items-center justify-center shadow-xl hover:bg-sky-600
                                    transition-all duration-300 transform hover:scale-105"
                >
                  <span>Get Started FREE</span>
                </button>
                <button
                  onClick={() => navigate("/BookDemo")}
                  className="border border-blue-900 text-blue-900 px-8 py-4 rounded-lg font-semibold
                                    flex items-center justify-center hover:bg-blue-50
                                    transition-all duration-300 transform hover:scale-105"
                >
                  Book a Demo
                </button>
              </div>
              <p className="text-gray-600 text-sm mt-2">
                Get started with free tools, or get a demo of our premium
                services.
              </p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full lg:w-2/5"
          >
            <div className="w-full rounded-2xl overflow-hidden shadow-xl">
              <img
                src={DoctorImage}
                alt="Healthcare Professionals"
                className="w-full h-auto object-cover max-h-[400px]"
                loading="lazy"
              />
            </div>
          </motion.div>
        </div>
        <div className="w-2/3 h-px bg-gray-300 my-10" />

        <div className="flex flex-col items-center space-y-2 z-20">
          <img
            src={DigitalSecurityImage}
            alt="Digital Security"
            className="w-12 h-12 mb-1"
          />
          <div className="text-center">
            <h3
              className="text-lg font-semibold text-blue-900 mb-1 cursor-pointer hover:text-sky-600"
              onClick={() => navigate("/data-privacy-policy")}
            >
              Data Privacy & Security
            </h3>
            <p className="text-gray-600 text-sm">
              Your privacy is our priority. We adhere to strict data protection
              standards applicable to the data processing and strive to protect
              patient and personal data.
            </p>
          </div>
        </div>
      </div>

      {/* DESKTOP LANDING TILE */}
      <div className="hidden md:flex w-full h-40vh bg-cover bg-center flex justify-center items-center relative">
        {/* BG Image */}
        <div style={{ overflow: "hidden" }} className="homeBg w-full h-[80vh]">
          <LazyLoadImage
            src={HomeBg}
            effect="blur"
            width={"100vw"}
            height={"80vh"}
            placeholderSrc={HomeBg}
            alt="Background Image"
            className="w-full h-[80vh] object-cover"
          />
        </div>

        {/* Image Left Text */}
        <div className="p-6 rounded-md absolute w-1/2 lg:w-[30vw] xl:w-[30vw] text-left left-5 top-12 lg:top-8 xl:top-[3vw] flex flex-col">
          <h1 className="text-[2.5vw] leading-[6vw] font-bold mb-4 text-[#2A79BC]">
            Your Digital Partner
            <br /> In Healthcare
          </h1>
          <p className="text-lg w-[80%] leading-[2.3vw] font-semibold mt-2">
            Welcome to the future of Healthcare, where Patients will be at the
            center and will truly drive the type of care they and their family
            receive, at a fraction of a cost.
          </p>
        </div>

        {/* PROGRAM SUNITA */}
        <div
          onClick={() => {
            navigate("/Sunita");
          }}
          className="bg-[#3CA5DC] border-b-[8px] border-l-[8px] border-t-[8px] flex flex-col w-[25vw] xl:w-[20vw] justify-center align-center items-start pl-8 pr-2 py-4 absolute top-10 right-0 rounded-l-3xl cursor-pointer"
        >
          <h1 className={`text-[1.5vw] text-left font-semibold text-[#FFFFFF]`}>
            PROGRAM <span className="text-[#FFD500]">SUNITA</span>
          </h1>
          <p className={`text-[1vw] text-left font-semibold text-[#FFFFFF]`}>
            CERVICAL CANCER MUKT BHARAT
          </p>
        </div>

        {/* Image Bottom Tiles */}
        <div className="flex flex-row w-full lg:w-[80vw] lg:mx-[10vw] mx-2 justify-around align-center items-stretch absolute bottom-[-0.5vh]">
          <div
            style={{ borderBottomColor: "#B6E6FD" }}
            className="bg-white flex rounded-t-xl border-b-[20px] border-b-sky-100 shadow-b-lg flex justify-center align-center items-center w-[22vw] px-4 py-6 shadow-xl cursor-pointer hover:scale-105 duration-500"
            onClick={() => {
              navigate("/ForPatientsCaregivers");
            }}
          >
            <h1 className="font-semibold">For Patients & Caregivers</h1>
          </div>
          <div
            style={{ borderBottomColor: "#DDF2C1" }}
            className="bg-white flex rounded-t-xl border-b-[20px] border-b-sky-100 shadow-b-lg flex justify-center align-center items-center w-[22vw] px-4 py-6 shadow-xl cursor-pointer hover:scale-105 duration-500"
            onClick={() => {
              navigate("/ForHospitalsClinics");
            }}
          >
            <h1 className="font-semibold">For Hospitals & Clinics</h1>
          </div>
          <div
            style={{ borderBottomColor: "#FACFD9" }}
            className="bg-white flex rounded-t-xl border-b-[20px] border-b-sky-100 shadow-b-lg flex justify-center align-center items-center w-[22vw] px-4 py-6 shadow-xl cursor-pointer hover:scale-105 duration-500"
            onClick={() => {
              navigate("/OurProducts");
            }}
          >
            <h1 className="font-semibold">Our Products</h1>
          </div>
        </div>
      </div>

      {/* MOBILE LANDING TILE */}
      <div className="block md:hidden w-full h-40vh bg-cover bg-center flex flex-col justify-center items-center">
        {/* BG Image */}
        <div className="homeBg w-full">
          <img
            src={HomeBg}
            alt="Home background"
            className="w-full h-auto object-cover"
          />
        </div>

        {/* Image Text */}
        <div className="px-[10vw] w-full text-left flex flex-col">
          <h1 className="text-4xl leading-[6vh] font-bold text-[#2A79BC] mt-5">
            Your Digital Partner In Healthcare
          </h1>
          <p className="text-lg leading-[4vh] font-semibold mt-5">
            Welcome to the future of Healthcare, where Patients will be at the
            center and will truly drive the type of care they and their family
            receive, at a fraction of a cost.
          </p>
        </div>

        {/* Image Bottom Tiles */}
        <div className="grid grid-cols-2 gap-4 w-full mx-2 px-[10vw] justify-around align-center items-stretch mt-5">
          <div
            style={{ borderBottomColor: "#B6E6FD" }}
            className="bg-white flex rounded-t-xl border-b-[20px] border-b-sky-100 shadow-b-lg flex justify-center align-center items-center w-full py-6 px-4 shadow-xl cursor-pointer hover:scale-105 duration-500"
            onClick={() => {
              navigate("/ForPatientsCaregivers");
            }}
          >
            <h1 className="font-semibold">For Patients & Caregivers</h1>
          </div>
          <div
            style={{ borderBottomColor: "#DDF2C1" }}
            className="bg-white flex rounded-t-xl border-b-[20px] border-b-sky-100 shadow-b-lg flex justify-center align-center items-center w-full py-6 px-4 shadow-xl cursor-pointer hover:scale-105 duration-500"
            onClick={() => {
              navigate("/ForHospitalsClinics");
            }}
          >
            <h1 className="font-semibold">For Hospitals & Clinics</h1>
          </div>
          <div
            style={{ borderBottomColor: "#FACFD9" }}
            className="bg-white flex rounded-t-xl border-b-[20px] border-b-sky-100 shadow-b-lg flex justify-center align-center items-center w-full py-6 px-4 shadow-xl cursor-pointer hover:scale-105 duration-500"
            onClick={() => {
              navigate("/ourProducts");
            }}
          >
            <h1 className="font-semibold">Our Products</h1>
          </div>
        </div>
      </div>

      <div className="mt-[10vh] md:my-[15vh] flex flex-col md:flex-row px-[10vw] gap-10 items-center">
        {/* DESKTOP Video Player */}
        <div className="hidden md:flex justify-center align-center items-center bg-black w-[50vw] h-[28.1vw] rounded-xl shadow-[#D9D9D9] shadow-xl">
          <ReactPlayer
            style={{ border: "3px solid black", borderRadius: "10px" }}
            url="<https://youtu.be/Oie6UdFwnMU?si=WRXk2rLNXNVp8Z0V>"
            playing={true}
            loop={true}
            controls={true}
            muted
            width={"50vw"}
            height={"28.1vw"}
            wrapper={"div"}
            onError={handleVideoPlayerError}
          />

          {/* Placeholder gray background since there's no video */}
          {/* <div className="bg-[#D9D9D9] w-full h-full flex justify-center items-center shadow-[#D9D9D9] shadow-lg rounded-xl">
                        <p className="text-center text-[#000000] font-bold text-3xl">Video</p>
                    </div> */}
        </div>

        {/* DESKTOP Text Content */}
        <div className="hidden md:flex w-full md:w-[28vw] h-auto md:py-[5em] flex flex-col justify-center items-center">
          <h1 className="text-[2.5vw] font-bold text-[#3CA5DC] text-center leading-[4vw]">
            AllesHealth <br />
            Is <br />
            Available Now <br />
            For <br />
            Patients & Carers
          </h1>
          <div className="flex flex-row justify-center md:justify-start items-center h-[6vh] gap-10 mt-10">
            <a
              href={"https://apps.apple.com/app/alleshealth/id6449357604"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={StoreIcon}
                alt="App Store"
                className="md:w-[11vw] w-[15vw] md:h-[6vh] object-contain"
              />
            </a>
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.alleshealth"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={PlayIcon}
                alt="Google Play"
                className="md:w-[11vw] w-[15vw] md:h-[6vh] object-contain"
              />
            </a>
          </div>
        </div>

        {/* MOBILE Video Player */}
        <div className="block md:hidden justify-center align-center items-center w-full h-auto rounded-xl shadow-[#D9D9D9] shadow-xl">
          <ReactPlayer
            style={{}}
            className="rounded-xl"
            url="<https://youtu.be/Oie6UdFwnMU?si=WRXk2rLNXNVp8Z0V>"
            playing={true}
            loop={true}
            controls={true}
            muted
            width={"80vw"}
            height={"45vw"}
            wrapper={"div"}
            onError={handleVideoPlayerError}
          />

          {/* Placeholder gray background since there's no video */}
          {/* <div className="bg-[#D9D9D9] w-full h-full flex justify-center items-center shadow-[#D9D9D9] shadow-lg rounded-xl">
                        <p className="text-center text-[#000000] font-bold text-3xl">Video</p>
                    </div> */}
        </div>

        <div className="block md:hidden w-[80vw] mx-[10vw] flex flex-row justify-center md:justify-start items-center gap-10 mt-[1vw]">
          <a
            href={"https://apps.apple.com/app/alleshealth/id6449357604"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={StoreIcon}
              alt="App Store"
              className="w-[30vw] h-auto object-contain"
            />
          </a>
          <a
            href={
              "https://play.google.com/store/apps/details?id=com.alleshealth"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={PlayIcon}
              alt="Google Play"
              className="w-[30vw] h-auto object-contain"
            />
          </a>
        </div>

        {/* MOBILE Text Content */}
        <div className="block md:hidden w-full md:w-[30vw] h-auto pt-[2em] pb-[3em] flex flex-col justify-center items-center">
          <h1 className="text-4xl font-bold text-[#3CA5DC] text-left leading-[5vh]">
            AllesHealth Is Available Now For Patients & Carers
          </h1>
        </div>
      </div>

      {/* Tile */}
      {/* <div className="mb-[10vh] flex justify-center items-center gap-[5vw]">
                <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="relative w-[80vw] mx-[10vw]">
                    <img src={Tile} alt="Tile" className="w-full h-auto object-cover" />
                    <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="absolute bottom-[12vw] right-[9vw] w-[10vw] h-1/5"></a>
                </a>
            </div> */}
      <div className="w-[80vw] mx-[10vw] mt-[10vh] flex flex-col justify-center items-center">
        <div className="flex flex-row justify-center items-center gap-10 mb-8">
          <h1 className="text-4xl font-bold text-[#3CA5DC] text-center">
            ALLESHEALTH CONNECT
          </h1>
        </div>
      </div>
      <div className="w-[90vw] h-auto">
        <img
          src={TileNew}
          alt="Tile"
          className="w-[100vw] h-auto object-cover"
        />
      </div>
      <div className="w-[80vw] mx-[10vw] mb-[10vh] flex flex-col justify-center items-center">
        <div
          onClick={() => {
            navigate("/ourproducts");
          }}
          className="md:ml-4 bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-full lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500"
        >
          Learn More <MdOutlineArrowOutward />
        </div>
      </div>

      {/* Our Partners & Associations */}
      <div className="w-[80vw] mx-[10vw] my-[10vh] flex flex-col justify-center items-center">
        <div className="flex flex-row justify-center items-center gap-10 mb-10">
          <h1 className="text-4xl font-bold text-[#FFB042] text-center">
            Our Partners & Associations
          </h1>
        </div>
        <div className="mb-[12vh] flex flex-col justify-center items-center gap-[8vh]">
          <div className="flex flex-col sm:flex-row justify-center items-center gap-[8vh] sm:gap-[5vw]">
            <div className="flex justify-center items-center">
              <img
                src={s3}
                alt="Our Partners & Associations"
                className="w-40 sm:w-64 h-auto object-contain"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={f1}
                alt="Our Partners & Associations"
                className="w-40 sm:w-64 h-auto object-contain"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={f2}
                alt="Our Partners & Associations"
                className="w-40 sm:w-36 h-auto object-contain"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={f3}
                alt="Our Partners & Associations"
                className="w-40 sm:w-64 h-auto object-contain"
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-[8vh] sm:gap-[5vw]">
            <div className="flex justify-center items-center">
              <img
                src={f4}
                alt="Our Partners & Associations"
                className="w-40 sm:w-64 h-auto object-contain"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={s1}
                alt="Our Partners & Associations"
                className="w-40 sm:w-52 h-auto object-contain"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={s2}
                alt="Our Partners & Associations"
                className="w-40 sm:w-52 h-auto object-contain"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={f5}
                alt="Our Partners & Associations"
                className="w-40 sm:w-52 h-auto object-contain"
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-[8vh] sm:gap-[5vw] mt-5">
            <div className="flex justify-center items-center">
              <img
                src={s4}
                alt="Our Partners & Associations"
                className="w-40 sm:w-64 h-auto object-contain"
              />
            </div>
          </div>
        </div>
      </div>

      <hr className="mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black" />

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Home;
