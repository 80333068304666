
import toast from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Footer from '../components/Footer';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  Heart, Brain, Beaker, Apple, Pill, Shield, Stethoscope, Bone, Activity, HeartPulse, X, FlaskConical, Gauge, Microscope, GanttChartSquare
} from 'lucide-react';

import contactEndpoints from "../services/apis";

const { WAITLIST_API } = contactEndpoints;


const CancerIcon = () => (
  <svg viewBox="0 0 24 24" className="w-4 h-4 animate-pulse" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="3" />
    <path d="M3 12h6M15 12h6M12 3v6M12 15v6" />
  </svg>
);

const ImmuneIcon = () => (
  <svg viewBox="0 0 24 24" className="w-4 h-4 animate-bounce" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M12 2L4 7v10l8 5 8-5V7l-8-5z" />
  </svg>
);

const ThyroidIcon = () => (
  <svg viewBox="0 0 24 24" className="w-4 h-4 animate-spin" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M12 2a5 5 0 0 0-5 5v3a5 5 0 0 0 10 0V7a5 5 0 0 0-5-5z" />
    <path d="M8 15a4 4 0 0 0 8 0" />
  </svg>
);

const FemaleIcon = () => (
  <svg viewBox="0 0 24 24" className="w-4 h-4 animate-pulse" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="8" r="5" />
    <path d="M12 13v8M9 18h6" />
  </svg>
);

const MaleIcon = () => (
  <svg viewBox="0 0 24 24" className="w-4 h-4 animate-bounce" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="10" cy="14" r="5" />
    <path d="M19 5l-5.4 5.4M15 5h4v4" />
  </svg>
);

const AeonixBiomarkers = () => {
  const biomarkers = [
    {
      title: 'Heart',
      count: 15,
      icon: <Heart className="w-4 h-4 text-red-500" />,
      gradient: 'bg-gradient-to-br from-red-50 to-red-100'
    },
    {
      title: 'Cancer Detection',
      count: 7,
      icon: <CancerIcon className="text-purple-500" />,
      gradient: 'bg-gradient-to-br from-purple-50 to-purple-100'
    },
    {
      title: 'Immune',
      count: 10,
      icon: <ImmuneIcon className="text-orange-500" />,
      gradient: 'bg-gradient-to-br from-orange-50 to-orange-100'
    },
    {
      title: 'Thyroid',
      count: 7,
      icon: <ThyroidIcon className="text-indigo-500" />,
      gradient: 'bg-gradient-to-br from-indigo-50 to-indigo-100'
    },
    {
      title: 'Female Health',
      count: 10,
      icon: <FemaleIcon className="text-pink-500" />,
      gradient: 'bg-gradient-to-br from-pink-50 to-pink-100'
    },
    {
      title: 'Male Health',
      count: 11,
      icon: <MaleIcon className="text-blue-500" />,
      gradient: 'bg-gradient-to-br from-blue-50 to-blue-100'
    }
  ];

  return (
    <div className="max-w-[240px] mx-auto">
      <div className="border-[10px] border-black rounded-[2rem] relative overflow-hidden min-h-[480px]">
        {/* Lighter background gradient */}
        <div className="absolute inset-0 bg-white">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-100 via-blue-200 to-blue-300" />

          <div className="absolute -top-32 -right-32 w-64 h-64 bg-blue-200/50 rounded-full blur-3xl" />
          <div className="absolute -bottom-32 -left-32 w-64 h-64 bg-blue-100/50 rounded-full blur-3xl" />
        </div>

        {/* Notch */}
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-24 h-5 bg-black rounded-b-xl z-10" />

        {/* Content */}
        <div className="pt-8 pb-4 px-3 relative">
          <div className="mb-4 text-center">
            <h1 className="text-2xl font-bold bg-gradient-to-r from-blue-800 to-blue-900 bg-clip-text text-transparent">Aeonix™</h1>
            <div className="flex items-center justify-center gap-1">
              <span className="text-sm font-medium bg-gradient-to-r from-blue-800 to-blue-900 bg-clip-text text-transparent">Over 200+ Tests @</span>
              <span className="text-sm font-bold text-blue-900">$595/year</span>
            </div>
          </div>

          {/* Grid Layout */}
          <div className="grid grid-cols-2 gap-3">
            {biomarkers.map((marker) => (
              <div
                key={marker.title}
                className={`${marker.gradient} p-3 rounded-xl flex flex-col items-start shadow-sm hover:shadow-md transition-shadow duration-200`}
              >
                <div className="flex items-center gap-1.5 mb-1">
                  {marker.icon}
                  <span className="text-xs font-medium text-gray-800">{marker.title}</span>
                </div>
                <div className="text-xs text-gray-600 ml-1">
                  {marker.count}<br />Biomarkers
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const WaitlistForm = ({ show, hide }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Age: '',
    Country: '',
    Phone: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading('Submitting...');

    try {
      const response = await axios.post(WAITLIST_API, formData);
      console.log('Form submitted:', response.data);
      toast.dismiss(toastId);
      toast.success('Successfully joined the waitlist!');
      setSubmitted(true);

      setTimeout(() => {
        hide();
        setSubmitted(false);
        setFormData({
          Name: '',
          Email: '',
          Age: '',
          Country: '',
          Phone: ''
        });
      }, 5000);
    } catch (error) {
      console.error('Submission error:', error);
      toast.dismiss(toastId);
      toast.error(error.response?.data?.message || 'Failed to join waitlist. Please try again.');
    }
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    show &&
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl p-6 w-full max-w-md relative animate-fadeIn">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-[#2B3674]">Join Aeonix™ Waitlist</h2>
          <button
            onClick={hide}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {submitted ? (
          <div className="bg-green-50 border border-green-200 rounded-lg p-4">
            <p className="text-green-800">
              Thank you for joining our waitlist! We'll be in touch soon.
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="Name" className="block text-sm font-medium text-gray-700 text-left mb-2">
                Full Name
              </label>
              <input
                id="Name"
                name="Name"
                required
                value={formData.Name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 
                  focus:ring-sky-500 focus:border-transparent outline-none transition-all duration-300 text-left"
                placeholder="John Doe"
              />
            </div>

            <div>
              <label htmlFor="Email" className="block text-sm font-medium text-gray-700 text-left mb-2">
                Email
              </label>
              <input
                id="Email"
                name="Email"
                type="email"
                required
                value={formData.Email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 
                  focus:ring-[#3CA5DC] focus:border-transparent outline-none transition-all duration-300 text-left"
                placeholder="john@example.com"
              />
            </div>

            <div>
              <label htmlFor="Age" className="block text-sm font-medium text-gray-700 text-left mb-2">
                Age
              </label>
              <input
                id="Age"
                name="Age"
                type="number"
                required
                min="18"
                max="120"
                value={formData.Age}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 
                  focus:ring-[#3CA5DC] focus:border-transparent outline-none transition-all duration-300 text-left"
                placeholder="25"
              />
            </div>

            <div>
              <label htmlFor="Country" className="block text-sm font-medium text-gray-700 text-left mb-2">
                Country
              </label>
              <input
                id="Country"
                name="Country"
                required
                value={formData.Country}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 
                  focus:ring-[#3CA5DC] focus:border-transparent outline-none transition-all duration-300 text-left"
                placeholder="Switzerland"
              />
            </div>

            <div>
              <label htmlFor="Phone" className="block text-sm font-medium text-gray-700 text-left mb-2">
                Phone Number
              </label>
              <input
                id="Phone"
                name="Phone"
                type="tel"
                required
                value={formData.Phone}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 
                  focus:ring-[#3CA5DC] focus:border-transparent outline-none transition-all duration-300 text-left"
                placeholder="+1 (555) 000-0000"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-[#3CA5DC] hover:bg-[#2B8CB8] text-white font-semibold py-3 
                rounded-lg transform hover:scale-105 transition-all duration-300"
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};


const HowItWorks = () => {
  const steps = [
    {
      number: "01",
      title: "Schedule Lab Test",
      description: "Schedule over 200+ lab tests at Aeonix Partnered Labs",
      icon: (
        <div className="w-20 h-20 flex items-center justify-center bg-blue-50 rounded-full">
          <svg viewBox="0 0 24 24" className="w-12 h-12" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
            <path d="M9 11h6" />
            <path d="M12 8v6" />
          </svg>
        </div>
      )
    },
    {
      number: "02",
      title: "Get Results",
      description: "Test Results + Clinician's Summary + TeleConsultation for critical results",
      icon: (
        <div className="w-20 h-20 flex items-center justify-center bg-blue-50 rounded-full">
          <svg viewBox="0 0 24 24" className="w-12 h-12" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
          </svg>
        </div>
      )
    },
    {
      number: "03",
      title: "Take Action, Test Again",
      description: "Lifetime Tracking of Results",
      icon: (
        <div className="w-20 h-20 flex items-center justify-center bg-blue-50 rounded-full">
          <svg viewBox="0 0 24 24" className="w-12 h-12" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
            <path d="M12 6v6l4 2" />
          </svg>
        </div>
      )
    }
  ];

  return (
    <div className="w-full bg-gradient-to-b from-gray-50 to-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-20"
        >
          <h2 className="text-5xl font-bold text-[#2B3674] mb-6">How It Works</h2>
          <p className="text-xl text-gray-600">Simple steps to get started with Aeonix™</p>
        </motion.div>
        <div className="relative grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Connector Lines Container */}
          <div className="hidden md:block absolute top-1/2 left-0 w-full h-1 -translate-y-1/2">
            <motion.div
              initial={{ scaleX: 0 }}
              whileInView={{ scaleX: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.5 }}
              className="w-full h-full bg-gradient-to-r from-[#3CA5DC] via-blue-400 to-[#3CA5DC]"
            />
            {/* Animated Dots */}
            <motion.div
              initial={{ x: '0%' }}
              animate={{ x: '100%' }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "linear"
              }}
              className="absolute top-1/2 -translate-y-1/2 w-4 h-4 bg-blue-500 rounded-full shadow-lg"
            />
          </div>

          {steps.map((step, index) => (
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              whileHover={{ y: -8 }}
              key={step.number}
              className="relative flex flex-col items-start p-8 bg-white rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300"
            >
              {/* Number Badge */}
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="absolute -top-4 -left-4 w-10 h-10 bg-[#3CA5DC] text-white rounded-lg flex items-center justify-center font-bold text-lg shadow-lg"
              >
                {step.number}
              </motion.div>

              {/* Content */}
              <div className="pt-6 w-full">
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className="mb-6 text-[#3CA5DC]"
                >
                  {step.icon}
                </motion.div>
                <h3 className="text-2xl font-bold text-[#2B3674] mb-4">{step.title}</h3>
                <p className="text-lg text-gray-600 leading-relaxed">{step.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

//200+ Test Lab section
const LabTestsSection = () => {
  const testCategories = [
    {
      title: "Hormonal Health",
      icon: <Brain className="w-8 h-8" />
    },
    {
      title: "Metabolic Health",
      icon: <FlaskConical className="w-8 h-8" />
    },
    {
      title: "Cardiovascular Health",
      icon: <HeartPulse className="w-8 h-8" />
    },
    {
      title: "Nutritional Health",
      icon: <Apple className="w-8 h-8" />
    },
    {
      title: "Liver and Kidney Function",
      icon: <Pill className="w-8 h-8" />
    },
    {
      title: "Immunity and Inflammation",
      icon: <Shield className="w-8 h-8" />
    },
    {
      title: "Gut Health",
      icon: <Microscope className="w-8 h-8" />
    },
    {
      title: "Bone and Joint Health",
      icon: <Bone className="w-8 h-8" />
    },
    {
      title: "General Health and Wellness",
      icon: <Activity className="w-8 h-8" />
    },
    {
      title: "Genetic and Advanced Testing",
      icon: <Heart className="w-8 h-8" />
    }
  ];
  return (
    <div className="w-full bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold text-[#2B3674] mb-6">200+ lab tests</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Doctors across specialties have thoughtfully curated each test to provide you with
            the most comprehensive understanding of your evolving health. The following areas
            for testing are included in your <span className="font-semibold">$595 annual membership</span> unless
            stated otherwise.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
          {testCategories.map((category, index) => (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              key={category.title}
              className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300"
            >
              <motion.div
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.6 }}
                className="w-16 h-16 rounded-full bg-blue-50 flex items-center justify-center mb-4 mx-auto text-[#3CA5DC]"
              >
                {category.icon}
              </motion.div>
              <h3 className="text-lg font-semibold text-[#2B3674] text-center">
                {category.title}
              </h3>
            </motion.div>
          ))}
        </div>

        {/* Decorative elements */}
        <div className="absolute top-1/2 left-0 w-64 h-64 bg-blue-50 rounded-full mix-blend-multiply filter blur-xl opacity-25 animate-blob" />
        <div className="absolute top-1/2 right-0 w-64 h-64 bg-purple-50 rounded-full mix-blend-multiply filter blur-xl opacity-25 animate-blob animation-delay-2000" />
      </div>
    </div>
  );
};

const Aeonix = () => {
  const features = [
    "200+ Lab tests yearly",
    "Doctor Insights / Clinician's Summary",
    "Lifetime Tracking of Results",
    "Testing at Aeonix Partners"
  ];

  const [show, setShow] = useState(false);

  return (
    <div className="bg-white min-h-screen">
      {/* Helmet for SEO */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aeonix - Alleshealth</title>
        <meta name="description" content="Aeonix - Alleshealth" />
        <meta
          name="keywords"
          content="Aeonix, Alles, alleshealth, health, Aeonix Alleshealth"
        />
      </Helmet>

      <div className="relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
          <div className="absolute top-0 -left-4 w-72 h-72 bg-blue-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob" />
          <div className="absolute top-0 -right-4 w-72 h-72 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000" />
          <div className="absolute -bottom-8 left-20 w-72 h-72 bg-pink-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000" />
        </div>

        {/* Hero Section */}
        <div className="relative flex flex-col justify-center items-center mt-[15vh] mb-[10vh]">
          <div className="w-[85vw] flex flex-col lg:flex-row justify-between items-center gap-16">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="w-full lg:w-3/5"
            >
              <h1 className="text-5xl lg:text-6xl font-bold text-sky-500 mb-8 leading-tight">
                Early Diagnosis
                <span className="block text-blue-900">Saves Lives</span>
              </h1>
              <p className="text-xl mb-10 text-gray-600 leading-relaxed">
                Aeonix™ provides comprehensive health insights through over 200+ lab tests,
                empowering individuals with early diagnosis and actionable health information.
              </p>

              <div className="flex items-end">
                <div className="space-y-6 mb-10">
                  {features.map((feature, index) => (
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      key={feature}
                      className="flex items-center gap-4"
                    >
                      <span className="flex items-center justify-center w-6 h-6 rounded-full bg-[#3CA5DC] text-white text-sm">✓</span>
                      <span className="text-lg text-gray-700">{feature}</span>
                    </motion.div>
                  ))}
                </div>

                <div
                  onClick={() => setShow(true)}
                  className="bg-[#3CA5DC] hover:bg-[#2B8CB8] text-white font-semibold py-5 px-20 rounded-lg 
            transform hover:scale-105 transition-all duration-300 animate-bounce h-12 flex items-center justify-center cursor-pointer"
                >
                  Join Waitlist
                </div>
              </div>

              <WaitlistForm show={show} hide={() => setShow(!show)} />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="w-full lg:w-2/5"
            >
              <AeonixBiomarkers />
            </motion.div>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <HowItWorks />

      {/* New Lab Tests Section */}
      <LabTestsSection />

      <Footer />
    </div>
  );
};

export default Aeonix;
