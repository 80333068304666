import React from "react";
import LandingTile from "../components/LandingTile";
import bgImage from "../assets/ForHealthcareServiceProviders/bgImage.png";
import Circle1 from "../assets/ForHealthcareServiceProviders/Circle1.png";
import Circle2 from "../assets/ForHealthcareServiceProviders/HospitalRight.png";
import Circle3 from "../assets/ForHealthcareServiceProviders/HospitalLeft.png";
import Circle4 from "../assets/ForHealthcareServiceProviders/WhatRight.png";
import ForHospitalsandClinics from "../assets/ForHealthcareServiceProviders/ForHospitalsandClinics.png";
import Icon5 from "../assets/ForHealthcareServiceProviders/Icon5.png";
import Icon6 from "../assets/ForHealthcareServiceProviders/Icon6.png";
import Icon7 from "../assets/ForHealthcareServiceProviders/Icon7.png";
import Icon8 from "../assets/ForHealthcareServiceProviders/Icon8.png";
import What1 from "../assets/ForHealthcareServiceProviders/What1.png";
import What2 from "../assets/ForHealthcareServiceProviders/What2.png";
import What3 from "../assets/ForHealthcareServiceProviders/What3.png";
import What4 from "../assets/ForHealthcareServiceProviders/What4.png";
import What5 from "../assets/ForHealthcareServiceProviders/What5.png";
import What6 from "../assets/ForHealthcareServiceProviders/What6.png";
import What from "../assets/ForHealthcareServiceProviders/What.png";
import Tile1 from "../assets/ForHealthcareServiceProviders/Tile1.png";
import Tile2 from "../assets/ForHealthcareServiceProviders/Tile2.png";
import { MdOutlineArrowOutward } from "react-icons/md";
import bg2 from "../assets/ForHealthcareServiceProviders/bg2.png";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ForHospitalsClinics = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white min-h-screen scroll-smooth">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best HIPAA-Compliant & Custom EHR Solutions - AllesHealth
        </title>
        <meta
          name="description"
          content="Get the best EHR for specialists & small clinics. Our HIPAA-compliant, cloud-based patient management system offers seamless EHR integration & custom solutions."
        />
        <meta
          name="keywords"
          content="HIPAA compliant EHR, Best EHR for specialists, EHR integration services, cloud based patient management system, Custom EHR solutions, best clinic management software, Best EHR for small clinics, AI-powered digital patient engagement tools, Top-rated cloud-based EHR systems, Free EHR software with telehealth integration, Digital workflow automation for clinics"
        />
      </Helmet>

      <LandingTile
        bgImage={bgImage}
        headerText={"AllesHealth Connect:"}
        headerColor={"#000000"}
        subparagraphBold={"true"}
        subparagraphText={
          "Complete Hospital and Clinic Management with Remote Patient Monitoring"
        }
        subColor={"#000000"}
        bottomColor={"#DDF2C1"}
      />

      {/* Content */}
      <div className="w-full flex flex-col justify-center items-center">
        {/* About */}
        <div className="mt-[10vh] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
          <div className="w-[80vw] mx-[10vw] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
            <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0">
              <h1 className="text-[4.4vw] font-bold text-left text-[#3CA5DC]">
                About AllesHealth Connect
              </h1>
            </div>
            <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0 flex flex-col justify-center items-start">
              <h1 className="text-lg font-regular text-left text-[#000000]">
                AllesHealth provides healthcare services to end users i.e.
                Patients and Carers while also supporting Healthcare providers
                in digitizing their Business and Increasing Brand Visibility. We
                believe in quality Healthcare that should be accessible to
                everyone.
              </h1>
            </div>
          </div>
        </div>

        {/* Tile */}
        <a
          href="https://download.alleshealth.com/"
          target="_blank"
          className="relative my-[10vh] w-[80vw] mx-[10vw]"
        >
          <img src={Tile1} alt="Tile" className="w-full h-auto object-cover" />
        </a>

        {/* For Hospitals and Clinics */}
        <div className="relative flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
          {/* Background Image */}
          <div className="w-full flex justify-center items-center">
            <img
              src={bg2}
              alt="background"
              className="w-[100vw] h-[100vh] xxxs:max-sm:h-[200vh] sm:max-lg:h-[220vh] object-cover"
            />
          </div>

          <div className="absolute top-15 w-[90vw] mx-[5vw] flex flex-col lg:flex-row justify-center items-start gap-[5vw]">
            {/* COntent */}
            <div className="w-[80vw] mx-[5vw] lg:w-3/5 lg:mx-0">
              <div className="flex flex-row justify-start items-center gap-10">
                <div className="w-14 pr-2 pb-4">
                  <img
                    src={ForHospitalsandClinics}
                    alt="Icon"
                    className="w-full h-10 object-contain"
                  />
                </div>
                <h1 className="mb-5 text-4xl md:text-[3.4vw] font-bold text-left text-[#3CA5DC]">
                  For Hospitals and Clinics
                </h1>
              </div>
              <h1 className="text-lg font-regular text-left text-[#000000] pl-5 mt-10">
                AllesHealth Is A Perfect Solution As A Digital Companion For
                Your Patients That Can Help Increase Efficiency Of Your Clinical
                Staff And Also Improve The Way You Engage With Your Patients.
              </h1>

              <div className="flex flex-row justify-start items-center gap-5 md:gap-10 mt-10 md:pl-10">
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img
                      src={Icon6}
                      alt="Icon"
                      className="w-12 h-12 object-contain"
                    />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">
                    Complete Hospital & Clinic Management
                  </h1>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img
                      src={Icon5}
                      alt="Icon"
                      className="w-12 h-12 object-contain"
                    />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">
                    Digital Companion for Patients
                  </h1>
                </div>
              </div>
              <div className="flex flex-row justify-start items-center gap-5 md:gap-10 mt-10 md:pl-10">
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img
                      src={Icon7}
                      alt="Icon"
                      className="w-12 h-12 object-contain"
                    />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">
                    Patient Centric Generated Clinical Insights
                  </h1>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img
                      src={Icon8}
                      alt="Icon"
                      className="w-12 h-12 object-contain"
                    />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">
                    Healthcare Data Monetisation for Hospitals and Clinics
                  </h1>
                </div>
              </div>

              <div
                onClick={() => {
                  navigate("/contactus");
                }}
                className="md:ml-4 mt-[10vh] bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-full lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500"
              >
                Reach out to Us <MdOutlineArrowOutward />
              </div>
            </div>

            {/* IMAGES on right */}
            <div className="w-full lg:w-2/5 pl-13 h-auto flex flex-col justify-center align-center items-center content-center relative lg:gap-[0vh]">
              <div className="w-full lg:ml-[15vh]">
                <img
                  src={Circle1}
                  alt="Image1"
                  className="w-3/5 lg:w-[20vw] lg:h-[20vw] object-cover bg-none rounded-full"
                />
              </div>
              <div className="w-full flex justify-end absolute lg:right-[-2vw] xxxs:max-lg:top-[30vw] top-[50%]">
                <img
                  src={Circle2}
                  alt="Image2"
                  className="w-1/2 lg:w-[20vw] lg:h-[20vw] object-contain rounded-full"
                />
              </div>
              <div className="mt-[5vh] lg:mt-[1vh] w-full">
                <img
                  src={Circle3}
                  alt="Image3"
                  className="w-3/5 ml-[1vw] lg:w-[15vw] lg:h-[15vw] object-contain rounded-full"
                />
              </div>
            </div>
          </div>
        </div>

        {/* What is in it for you */}
        <div className="my-[10vh] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
          <div className="w-[90vw] mx-[5vw] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
            {/* COntent */}
            <div className="w-[80vw] mx-[5vw] lg:w-3/4 lg:mx-0">
              <div className="flex flex-row justify-start items-center gap-10">
                <div className="w-20 pr-2 pb-4">
                  <img
                    src={What}
                    alt="Icon"
                    className="w-full h-14 object-contain"
                  />
                </div>
                <h1 className="mb-5 text-4xl md:text-[3.4vw] font-bold text-left text-[#3CA5DC]">
                  What is in it for you
                </h1>
              </div>

              <div className="flex flex-row justify-start items-center gap-5 md:gap-10 mt-10 md:pl-10">
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img
                      src={What1}
                      alt="Icon"
                      className="w-12 h-12 object-contain"
                    />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">
                    Reduce Administration Overload by over 60%
                  </h1>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img
                      src={What2}
                      alt="Icon"
                      className="w-12 h-12 object-contain"
                    />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">
                    Reduce Clinician Burnout
                  </h1>
                </div>
              </div>
              {/* <div className="flex flex-row justify-start items-center gap-5 md:gap-10 mt-10 md:pl-10">
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img src={What3} alt="Icon" className="w-12 h-12 object-contain" />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">Improve your Company/Brand Visibility</h1>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img src={What4} alt="Icon" className="w-12 h-12 object-contain" />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">Save Money on Marketing Budgets</h1>
                </div>
              </div> */}
              <div className="flex flex-row justify-start items-center gap-5 md:gap-10 mt-10 md:pl-10">
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img
                      src={What5}
                      alt="Icon"
                      className="w-12 h-12 object-contain"
                    />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">
                    Better Patient Satisfaction
                  </h1>
                </div>
                <div className="w-1/2 flex flex-row justify-start items-center gap-1 md:gap-2">
                  <div className="flex justify-center items-center rounded-full">
                    <img
                      src={What6}
                      alt="Icon"
                      className="w-12 h-12 object-contain"
                    />
                  </div>
                  <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">
                    Increase in Sales and more Patient Walk-ins
                  </h1>
                </div>
              </div>

              <div
                onClick={() => {
                  navigate("/ourProducts");
                }}
                className="md:ml-4 mt-[10vh] bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-full lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500"
              >
                More about Our Products <MdOutlineArrowOutward />
              </div>
            </div>

            <div className="flex justify-center items-center w-[80vw] mx-[10vw] lg:w-1/4 lg:mx-0">
              <img
                src={Circle4}
                alt=""
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>

        {/* Tile */}
        <div
          onClick={() => {
            navigate("/sunita");
          }}
          className="mb-[10vh] w-[80vw] mx-[10vw] cursor-pointer"
        >
          <img src={Tile2} alt="Tile" className="w-full h-auto object-cover" />
        </div>
      </div>

      <hr className="mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black" />

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default ForHospitalsClinics;
