import React from 'react'
import LandingTile from '../components/LandingTile'
import bgImage from '../assets/ForPatientsCaregivers/bgImage.jpg'
import Circle1 from '../assets/ForPatientsCaregivers/Circle1.png'
import Circle2 from '../assets/ForPatientsCaregivers/Circle2.png'
import Circle3 from '../assets/ForPatientsCaregivers/Circle3.png'
import ProgramSunita from '../assets/OurProducts/ProgramSunita.jpg'
import bgFlowers from '../assets/OurProducts/bgFlowers.png'
import { MdOutlineArrowOutward } from "react-icons/md";
import ForPatients from '../assets/ForPatientsCaregivers/ForPatients.png'
import ForCarers from '../assets/ForPatientsCaregivers/ForCarers.png'
import What from '../assets/ForPatientsCaregivers/What.png'
import Icon1 from '../assets/ForPatientsCaregivers/Icon1.png'
import Icon2 from '../assets/ForPatientsCaregivers/Icon2.png'
import Icon3 from '../assets/ForPatientsCaregivers/Icon3.png'
import Icon4 from '../assets/ForPatientsCaregivers/Icon4.png'
import Icon5 from '../assets/ForPatientsCaregivers/Icon5.png'
import Icon6 from '../assets/ForPatientsCaregivers/Icon6.png'
import Icon7 from '../assets/ForPatientsCaregivers/Icon7.png'
import Icon8 from '../assets/ForPatientsCaregivers/Icon8.png'
import Icon9 from '../assets/ForPatientsCaregivers/Icon9.png'
import Icon10 from '../assets/ForPatientsCaregivers/Icon10.png'
import Icon11 from '../assets/ForPatientsCaregivers/Icon11.png'
import Icon12 from '../assets/ForPatientsCaregivers/Icon12.png'
import Icon13 from '../assets/ForPatientsCaregivers/Icon13.png'
import Icon14 from '../assets/ForPatientsCaregivers/Icon14.png'
import Tile2 from '../assets/ForPatientsCaregivers/Tile2.png'
import Footer from '../components/Footer'
import TextComponent from '../components/TextComponent'
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

const ForPatientsCaregivers = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white min-h-screen scroll-smooth">

      <Helmet>
        <meta charSet="utf-8" />
        <title>Alleshealth - For Patients Caregivers</title>
        <meta
          name="description"
          content="At AllesHealth, we believe Health Data is the Soil through which many new trees can be planted and the fruits of those should be shared among all, especially Patients and Carers, as the rightful owners of Health Data"
        />
        <meta
          name="keywords"
          content="Alles, alles, allesheath, Alleshealth, health"
        />
      </Helmet>

      <LandingTile
        bgImage={bgImage}
        headerText={'For the best care of your loved ones'}
        headerColor={'#000000'}
        subparagraphText={'At AllesHealth, we believe Health Data is the \'Soil\' through which many new trees can be planted and the fruits of those should be shared among all, especially Patients and Carers, as the rightful owners of Health Data'}
        subColor={'#FFFFFF'}
        bottomColor={'#B6E6FD'} />

      <div className="flex flex-col-reverse lg:flex-row justify-center align-center w-[80vw] h-auto mx-[10vw] my-[2vh] lg:my-[10vh] gap-10">
        {/* TEXT on left */}
        <div className="flex flex-col justify-center align-center w-full lg:w-1/2">
          <TextComponent DATA={DATA} />
        </div>

        {/* IMAGES on right */}
        <div className="w-full lg:w-1/2 pl-13 h-auto flex flex-col justify-center align-center items-center content-center relative  lg:gap-[20vh]">
          <div className="homeBg w-full">
            <img src={Circle1} alt="Image1" className="w-3/5 lg:w-[24vw] lg:h-[24.5vw] object-contain rounded-full shadow-xl" />
          </div>
          <div className="homeBg w-full flex justify-end absolute right-[-3vw] xxxs:max-lg:top-[32vw]">
            <img src={Circle2} alt="Image2" className="w-1/2 lg:w-[25vw] lg:h-[23.5vw] object-contain rounded-full shadow-xl" />
          </div>
          <div className="homeBg mt-[7vh] lg:mt-[18vh] w-full">
            <img src={Circle3} alt="Image3" className="w-1/2 lg:w-[24vw] lg:h-[25vw] object-contain rounded-full shadow-xl" />
          </div>
        </div>
      </div>

      {/* Tile */}
      {/* <div onClick={()=>{navigate("/abha")}} className="mt-10 w-[93vw] ml-[5vw]">
        <img src={Tile} alt="Tile" className="w-full h-auto object-cover cursor-pointer" />
      </div> */}

      {/* Tile 2 */}
      <div onClick={() => { navigate("/sunita") }} className="mt-10 mb-[10vh] w-[86vw] lg:w-[90vw] h-full mx-[5vw] cursor-pointer">
        <img src={Tile2} alt="Tile" className="w-full h-auto object-cover" />
      </div>

      <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

      {/* FOOTER */}
      <Footer />
    </div>
  )
}

export default ForPatientsCaregivers;

const DATA = [
  {
    id: 1,
    Icon: ForPatients,
    header: 'For Patients',
    sub: 'AllesHealth Provides a Platform For Patients That Helps Them Proactively Manage Their Health and be Independent.',
    data: [
      {
        id: 1,
        icon: Icon1,
        text: 'Track your Health Journey and see progress.',
      },
      {
        id: 2,
        icon: Icon2,
        text: 'Maintain your Health Records all in one place. ',
      },
      {
        id: 3,
        icon: Icon3,
        text: 'Get Dosage reminders and mark calendars.',
      },
      {
        id: 4,
        icon: Icon4,
        text: 'Personalize your account data.',
      },
    ],
  },
  {
    id: 2,
    Icon: ForCarers,
    header: 'For Carers',
    sub: 'AllesHealth Provides a Platform For Carers by providing Insights of patients well being To Understand Disease Through Their Own Health Data.',
    data: [
      {
        id: 1,
        icon: Icon5,
        text: 'Get daily health tips and facts.',
      },
      {
        id: 2,
        icon: Icon6,
        text: 'Maintain a family tree and become super carer.',
      },
      {
        id: 3,
        icon: Icon7,
        text: 'Information about nearby Labs, Clinics and Hospitals.',
      },
      {
        id: 4,
        icon: Icon8,
        text: 'Give feedback and manage data consent.',
      },
    ],
  },
  {
    id: 3,
    Icon: What,
    header: 'What is in it for you',
    sub: 'We Want To Provide The Control Of Health Data To The Real Owner \'PATIENTS\' And Ensure Patients Are Rewarded For Healthy Behavior.',
    data: [
      {
        id: 1,
        icon: Icon9,
        text: 'Complete Transparency about your data usage.',
      },
      {
        id: 2,
        icon: Icon10,
        text: 'Get best health tips & recommendation',
      },
      {
        id: 3,
        icon: Icon11,
        text: 'Help in Personalized treatments.',
      },
      {
        id: 4,
        icon: Icon12,
        text: 'In hand availability of digital health records.',
      },
      {
        id: 5,
        icon: Icon13,
        text: 'Easy and simple to use interface and features',
      },
      {
        id: 6,
        icon: Icon14,
        text: 'Clinical health insights with AllesHealth',
      },
    ],
  },
]