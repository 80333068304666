import React, { useEffect } from 'react'
import LandingTile2 from '../components/LandingTile2'
import Footer from '../components/Footer'
import bgImage from '../assets/Careers/bgImage.png'
import upload from '../assets/Careers/upload.png';
import linkedinIcon from '../assets/Home/linkedinIcon.png';
import { MdOutlineFileUpload } from "react-icons/md";
import { Helmet } from "react-helmet-async";

const Careers = () => {
    const linkedinUrl = 'https://www.linkedin.com/company/alleshealth/';
    return (
        <div className="bg-white min-h-screen scroll-smooth">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Careers - Alleshealth</title>
                <meta
                    name="description"
                    content="Jobs At Alleshealth"
                />
                <meta
                    name="keywords"
                    content="Alles, alles, allesheath, Alleshealth, health, jobs, alleshealth jobs"
                />
            </Helmet>

            <LandingTile2
                bgImage={bgImage}
                bottomColor={'#35ABF4'} />

            {/* CONTENT */}
            <div className="flex w-[80vw] mx-[10vw] my-[8vh] flex-col justify-center items-center">
                <div className="flex flex-col items-start">
                    <h1 className="font-regular text-lg text-black text-left">
                        We are actively looking for people who are driven by the same purpose and can help us deliver on our mission.
                    </h1>
                    <h1 className="font-regular text-lg text-black text-left">
                        Contact us at <a href="mailto:info@alleshealth.com" style={{ textDecoration: 'underline' }}>info@alleshealth.com</a>
                    </h1>
                </div>

                <div className="flex w-full flex-col justify-center items-center">
                    <h1 className="font-bold text-4xl lg:text-[3.5vw] text-[#3CA5DC] my-[5vh] md:my-[10vh] text-center">JOBS AT ALLESHEALTH</h1>
                    <h1 className="font-regular w-[50vw] mx-[25vw] text-lg text-black text-center">
                        We receive huge amount of applications on regular basis, if your profile matches with any of the open roles, our team will reach out to you.
                    </h1>
                    <a href="mailto:info@alleshealth.com" style={{ backgroundColor: 'rgba(60, 165, 220, 0.13)' }} className="mt-8 py-5 px-12 flex flex-row justify-center items-center rounded-2xl shadow-xl gap-5 cursor-pointer hover:scale-105 duration-500">
                        <img
                            src={upload}
                            alt="Upload Icon"
                            className="w-8 h-8 object-contain cursor-pointer"
                        />
                        <h1 className="ml-5 font-semibold text-lg text-[#000000] text-center">
                            Send your Resume at <br />
                            info@alleshealth.com
                        </h1>
                    </a>
                    <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: 'rgba(60, 165, 220, 0.13)' }} className="mt-[5vh] py-5 px-10 flex flex-row justify-center items-center rounded-2xl shadow-xl gap-5 cursor-pointer hover:scale-105 duration-500">
                        <img
                            src={linkedinIcon}
                            alt="Linkedin Icon"
                            className="w-12 h-12 object-contain cursor-pointer"
                        />
                        <h1 className="font-semibold text-lg text-[#000000] text-center">
                            AllesHealth LinkedIn Page
                        </h1>
                    </a>
                    <h1 className="mt-8 font-regular w-[50vw] mx-[25vw] text-lg text-black text-center">
                        For latest Job postings, Refer to AllesHealth LinkedIn
                    </h1>
                </div>
            </div>

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div >
    )
}

export default Careers;

const DATA = [
    {
        id: 0,
        role: 'Full Stack Web Developer',
        position: 'Fresher',
    },
    {
        id: 1,
        role: 'Flutter Developer',
        position: '4+ yrs',
    },
    {
        id: 2,
        role: 'Project Manager',
        position: '2+ yrs',
    },
    {
        id: 3,
        role: 'Business Analyst',
        position: 'Fresher',
    },
]