import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import contactEndpoints from "../services/apis";
import Footer from '../components/Footer';

const { CONNECT_ALLES_HEALTH_API } = contactEndpoints;

const AllesHealthDemoForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    websiteUrl: '',
    employees: '',
    country: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const countries = [
    'United States', 'Canada', 'United Kingdom', 'Australia', 'Afghanistan', 'Albania', 'Algeria',
    'Argentina', 'Armenia', 'Austria', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belgium',
    'Belize', 'Bermuda', 'Brazil', 'Bulgaria', 'Cambodia', 'Cameroon', 'China', 'Colombia',
    'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Dominican Republic',
    'Ecuador', 'Egypt', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hong Kong',
    'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy',
    'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'South Korea', 'Kuwait', 'Latvia',
    'Lebanon', 'Lithuania', 'Luxembourg', 'Malaysia', 'Mexico', 'Morocco', 'Netherlands',
    'New Zealand', 'Nigeria', 'Norway', 'Oman', 'Pakistan', 'Panama', 'Peru', 'Philippines',
    'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Romania', 'Russia', 'Saudi Arabia',
    'Singapore', 'South Africa', 'Spain', 'Sri Lanka', 'Sweden', 'Switzerland', 'Taiwan',
    'Thailand', 'Turkey', 'Ukraine', 'United Arab Emirates', 'Vietnam', 'Yemen', 'Zimbabwe'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const payload = {
        Name: `${formData.firstName} ${formData.lastName}`,
        Email: formData.email,
        Phone: formData.phoneNumber,
        Country: formData.country,
        CompanyName: formData.companyName,
        WebsiteURL: formData.websiteUrl,
        Employees: formData.employees
      };

      await axios.post(CONNECT_ALLES_HEALTH_API, payload);

      // Show success message
      setShowSuccess(true);

      // Redirect to home page after 3 seconds
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error('Submission error:', error);
      alert('There was an error submitting the form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePrivacyPolicy = () => {
    navigate('/data-privacy-policy');
  };

  // If success message is shown, render a simplified success view
  if (showSuccess) {
    return (
      <div className="min-h-screen bg-[#f0f8ff] flex items-center justify-center p-4 sm:p-6">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-2xl shadow-2xl p-6 sm:p-10 text-center max-w-md w-full"
        >
          <svg
            className="mx-auto mb-4 sm:mb-6 w-16 h-16 sm:w-20 sm:h-20 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-3 sm:mb-4">
            Success!
          </h2>
          <p className="text-gray-600 mb-4 sm:mb-6">
            We've received your request. Our team will get back to you soon.
          </p>
          <p className="text-sm text-gray-500">
            Redirecting to home page...
          </p>
        </motion.div>
      </div>
    );
  }
  return (
    <div>
      <div className="relative min-h-[calc(100vh-4rem)] bg-[#f0f8ff] flex items-center justify-center p-4 sm:p-6 overflow-hidden">
        {/* Background Blob Decorations */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
          <div className="absolute top-0 -left-4 w-40 sm:w-72 h-40 sm:h-72 bg-blue-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob" />
          <div className="absolute top-0 -right-4 w-40 sm:w-72 h-40 sm:h-72 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000" />
          <div className="absolute -bottom-8 left-20 w-40 sm:w-72 h-40 sm:h-72 bg-pink-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000" />
        </div>

        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-6xl bg-white rounded-2xl shadow-2xl overflow-hidden grid grid-cols-1 lg:grid-cols-2 relative z-10"
        >
          {/* Left Side: Features */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="bg-[#e6f2ff] p-6 sm:p-8 lg:p-10 flex flex-col justify-center"
          >
            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-900 mb-4 sm:mb-6 leading-tight text-left">
              Get a Free Demo of
              <span className="block text-sky-600">AllesHealth Connect</span>
            </h1>
            <p className="text-gray-700 mb-6 sm:mb-8 text-base sm:text-lg text-left">
              AHC is an advanced EHR system that optimizes clinical workflows, enhances patient care, and boosts efficiency. AHC comes with seamless integration, intuitive design, and AI-driven automation and is FHIR-HL7 compliant.
            </p>

            <h2 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-5 text-blue-900">Popular Features</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5">
              <div>
                <h3 className="text-lg font-semibold mb-2 sm:mb-3 text-blue-800">For Clinics</h3>
                <ul className="space-y-2 sm:space-y-3">
                  {[
                    'Complete Patient EMR',
                    'Tele Health Platform',
                    'Invoicing',
                    'AI driven Analysis',
                    'Workflow Automation',
                    'Biomarker Analysis'
                  ].map((feature) => (
                    <motion.li
                      key={feature}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3 }}
                      className="flex items-center text-gray-700 text-sm sm:text-base"
                    >
                      <svg className="w-4 h-4 sm:w-5 sm:h-5 text-blue-500 mr-2 sm:mr-3 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      {feature}
                    </motion.li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2 sm:mb-3 text-blue-800 mt-4 sm:mt-0">For Patients</h3>
                <ul className="space-y-2 sm:space-y-3">
                  {[
                    'Doctor Interactions',
                    'Dosage Adherence',
                    'Personal Health Plan',
                    'Tele Consultation',
                    'Health Rewards'
                  ].map((feature) => (
                    <motion.li
                      key={feature}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3 }}
                      className="flex items-center text-gray-700 text-sm sm:text-base"
                    >
                      <svg className="w-4 h-4 sm:w-5 sm:h-5 text-blue-500 mr-2 sm:mr-3 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      {feature}
                    </motion.li>
                  ))}
                </ul>
              </div>
            </div>
          </motion.div>

          {/* Right Side: Form */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="p-6 sm:p-8 lg:p-10"
          >
            <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2 text-left">First Name *</label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Enter your first name"
                    required
                    value={formData.firstName}
                    onChange={handleChange}
                    className="block w-full px-3 sm:px-4 py-2 sm:py-2.5 rounded-lg border border-gray-300 bg-white text-gray-900 shadow-sm focus:border-sky-500 focus:ring-2 focus:ring-sky-500 transition-all duration-300 text-sm sm:text-base"
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2 text-left">Last Name *</label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Enter your last name"
                    required
                    value={formData.lastName}
                    onChange={handleChange}
                    className="block w-full px-3 sm:px-4 py-2 sm:py-2.5 rounded-lg border border-gray-300 bg-white text-gray-900 shadow-sm focus:border-sky-500 focus:ring-2 focus:ring-sky-500 transition-all duration-300 text-sm sm:text-base"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2 text-left">Email *</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email address"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="block w-full px-3 sm:px-4 py-2 sm:py-2.5 rounded-lg border border-gray-300 bg-white text-gray-900 shadow-sm focus:border-sky-500 focus:ring-2 focus:ring-sky-500 transition-all duration-300 text-sm sm:text-base"
                  />
                </div>
                <div>
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2 text-left">Phone number *</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Enter your phone number"
                    required
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="block w-full px-3 sm:px-4 py-2 sm:py-2.5 rounded-lg border border-gray-300 bg-white text-gray-900 shadow-sm focus:border-sky-500 focus:ring-2 focus:ring-sky-500 transition-all duration-300 text-sm sm:text-base"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5">
                <div>
                  <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2 text-left">Company name *</label>
                  <input
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder="Enter your company name"
                    required
                    value={formData.companyName}
                    onChange={handleChange}
                    className="block w-full px-3 sm:px-4 py-2 sm:py-2.5 rounded-lg border border-gray-300 bg-white text-gray-900 shadow-sm focus:border-sky-500 focus:ring-2 focus:ring-sky-500 transition-all duration-300 text-sm sm:text-base"
                  />
                </div>
                <div>
                  <label htmlFor="websiteUrl" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2 text-left">Website URL *</label>
                  <input
                    type="url"
                    name="websiteUrl"
                    id="websiteUrl"
                    placeholder="Enter your website URL"
                    required
                    value={formData.websiteUrl}
                    onChange={handleChange}
                    className="block w-full px-3 sm:px-4 py-2 sm:py-2.5 rounded-lg border border-gray-300 bg-white text-gray-900 shadow-sm focus:border-sky-500 focus:ring-2 focus:ring-sky-500 transition-all duration-300 text-sm sm:text-base"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5">
                <div>
                  <label htmlFor="employees" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2 text-left">Employees *</label>
                  <select
                    name="employees"
                    id="employees"
                    required
                    value={formData.employees}
                    onChange={handleChange}
                    className="block w-full px-3 sm:px-4 py-2 sm:py-2.5 rounded-lg border border-gray-300 bg-white text-gray-900 shadow-sm focus:border-sky-500 focus:ring-2 focus:ring-sky-500 transition-all duration-300 text-sm sm:text-base"
                  >
                    <option value="">Please Select</option>
                    <option value="1-10">1-10</option>
                    <option value="11-50">11-50</option>
                    <option value="51-200">51-200</option>
                    <option value="201-500">201-500</option>
                    <option value="500+">500+</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2 text-left">Country of Company Headquarters *</label>
                  <select
                    name="country"
                    id="country"
                    required
                    value={formData.country}
                    onChange={handleChange}
                    className="block w-full px-3 sm:px-4 py-2 sm:py-2.5 rounded-lg border border-gray-300 bg-white text-gray-900 shadow-sm focus:border-sky-500 focus:ring-2 focus:ring-sky-500 transition-all duration-300 text-sm sm:text-base"
                  >
                    <option value="">Please Select</option>
                    {countries.map((country) => (
                      <option key={country} value={country}>{country}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="text-xs sm:text-sm text-gray-600 mb-2 sm:mb-4">
                We're committed to your privacy. AllesHealth uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our <button
                  type="button"
                  onClick={handlePrivacyPolicy}
                  className="text-sky-500 hover:underline focus:outline-none"
                >
                  Privacy Policy
                </button>.
              </div>

              <motion.button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
                type="submit"
                disabled={isSubmitting}
                className={`w-full flex justify-center py-2.5 sm:py-3.5 px-4 border border-transparent rounded-lg shadow-lg text-sm font-semibold text-white transition-all duration-300 ${isSubmitting
                    ? 'bg-sky-400 cursor-not-allowed'
                    : 'bg-sky-600 hover:bg-sky-700 focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                  }`}
              >
                {isSubmitting ? 'Submitting...' : 'Get your free demo'}
              </motion.button>
            </form>
          </motion.div>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default AllesHealthDemoForm;